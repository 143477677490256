<template>
  <div class="promo-codes">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="content-path">
          <breadcrumbs :crumbs="breadcrumbs" />
        </div>
        <div class="page-tabs">
          <tab-tote
            :tabs="navTabs"
            :value="navTabSelected"
            @click="onClickNavTab"
            :tabDisable="tabArr"
          />
        </div>
        <router-view :url="url" />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import TabTote from "@/components/TabTote.vue";
export default {
  components: {
    TabTote,
    Breadcrumbs,
  },
  props: {
    url: String,
  },
  data() {
    return {
      breadcrumbs: [
        ["My Purchases", "/my-tickets"],
        ["Manage Tickets", null],
      ],
      navTabs: ["Upgrades", "Exchanges", "Transfers", "Refunds"],
      tabArr: [],
      enableUpgrade: false,
      enableExchange: false,
      enableRefund: false,
      enableTransfer: false,
    };
  },
  computed: {
    navTabSelected() {
      const path = this.$route.path;
      if (path.includes("upgrade") && this.enableUpgrade) {
        return 0;
      } else if (path.includes("exchange") && this.enableExchange) {
        return 1;
      } else if (path.endsWith("transfer") && this.enableTransfer) {
        return 2;
      } else if (path.includes("refund") && this.enableRefund) {
        return 3;
      } else {
        return 0;
      }
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    onClickNavTab(index) {
      const path = this.$route.path;
      if (index === 0 && !path.endsWith("upgrade") && this.enableUpgrade) {
        this.$router.push(`/tote-swap/upgrade`);
      } else if (
        index === 1 &&
        !path.endsWith("exchange") &&
        this.enableExchange
      ) {
        this.$router.push(`/tote-swap/exchange`);
      } else if (
        index === 2 &&
        !path.endsWith("transfer") &&
        this.enableTransfer
      ) {
        this.$router.push(`/tote-swap/transfer`);
      } else if (index === 3 && !path.endsWith("refund") && this.enableRefund) {
        this.$router.push(`/tote-swap/refund`);
      }
      this.$forceUpdate();
    },
  },
  async mounted() {
    this.$store.commit("setTitle", "Manage Tickets");
    this.$store.dispatch(
      "updateHelpVideoSrc",
      "https://youtube.com/embed/wgSiqHZnkZo"
    );

    if (this.user.isAuthenticated) {
      try {
        const { data } = await this.$axios.post(
          "/toteswap/fetch-planner-policy"
        );
        this.enableUpgrade = data.enableUpgrade;
        this.enableExchange = data.enableExchange;
        this.enableRefund = data.enableRefund;
        this.enableTransfer = data.enableTransfer;

        this.tabArr = [
          !data.enableUpgrade,
          !data.enableExchange,
          !data.enableRefund,
          !data.enableTransfer,
        ];
      } catch (error) {}
    }
  },
  beforeDestroy() {
    this.$store.dispatch("updateHelpVideoSrc", null);
  },
};
</script>

<style lang="less">
.page-tabs {
  margin-bottom: 25px;
}
</style>
